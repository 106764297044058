.title-plan ~ .dps-main-content{
  padding: 0;
  flex: 1;
}

.plan-header{
  display: flex;
  justify-content: space-between;
  align-items: center;
  
  .actions-plan{
    display: flex;
    align-items: center;
    padding-bottom: 10px;
    gap: 10px;

    button {
      padding: 0;
      border: 0;
      background: transparent;
      cursor: pointer;

      &.btn-add-plan{
        display: flex;
        align-items: center;
        font: 400 14px "Inter", sans-serif;

        svg {
          margin-right: 10px;
          margin-top: -1px;
        }
      }
    }

    .import-plan{

      button {
        padding: 2px 16px;
        border: 1px solid #DDDDDD;
        background: transparent;
        border-radius: 5px;
        font: 700 12px "Inter", sans-serif;
        color: #606060;
      }
    }
  }
}

.flight-plan {
  background-color: transparent !important;
  flex-direction: column;
  max-height: 100%;
  // overflow-y: auto;

  .flight-plan-area {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
  }

  .flight-selector {
    width: 100%;
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    align-self: stretch;
    position: relative;
    overflow-y: auto;

    .dps-list-f{
      .clmn {
        &:nth-child(1) {
          flex: 1 1 200px;
        }
        &:nth-child(2) {
          flex: 0 1 60px;
        }
        &:nth-child(3) {
          flex: 1 1 200px;
        }
        &:nth-child(4) {
          flex: 0 1 100px;
          text-align: center;
        }
        &:nth-child(5) {
          flex: 0 1 100px;
        }
      }
      .body {
        .clmn {
          &:nth-child(1) {
            font-weight: 700;
          }
          &:nth-child(2) {
            font-weight: 700;
          }
          svg {
            margin-right: 12px;
          }

          .table-btn{
            border: 0;
            padding: 0;
            color: $dark_neutral;
            background: transparent;

            svg{
              width: 15px;
              color: currentColor;
            }
          }
        }
      }
    }

    h1 {
      text-align: left;
    }
  }

  .flight-selector-nodata {
    max-width: 420px;
  }

  .flight-plan-detail {
    min-width: 400px;
    flex-flow: column;
    height: 100%;
    width: 100%;
    position: relative;
    display: flex;

    .form-plan{
      flex: 1;
      width: 100%;
      font-size: 14px;
      display: flex;
      flex-direction: column;

      .form-item {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 4px;

        & > span {
          color: $dark_neutral;
          font: 500 12px Inter;
          line-height: 15px; /* 125% */
          display:-webkit-box;
          -webkit-line-clamp:1;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
          word-break: break-word;
        }

        & > label {
          display: flex;
          height: 34px;
          justify-content: center;
          align-items: center;
          align-self: stretch;
          border-radius: 3px;
          border: 1px solid #7168CA;
          background: $white;
          position: relative;
          
          input {
            width: 100%;
            height: 100%;
            padding: 6px 10px;
            border: 0;
            border-radius: inherit;
            background: transparent;
            color: #000;
            font: 400 14px Inter;
          }

          .error-message {
            position: absolute;
            left: 0;
            top: calc(100% + 2px);
            height: 14px;
            line-height: 14px;
            font-size: 11px;
          }

          &.constant {
            border: 0;
          }
        }

        select {
          width: 100%;
          height: 100%;
          border: 0;
          border-radius: inherit;
          appearance: none;
          padding-left: 10px;
          padding-right: 26px;

          &:disabled{
            color: #000;
          }
        }

        select + svg {
          position: absolute;
          color: #473DAF;
          top: 50%;
          right: 4px;
          transform: translateY(-50%);
          pointer-events: none;
        }

        &.form-disable {
          label {
            border: 0;
            background: transparent;
            pointer-events: none;
          }
        }

        &.form-vertical {
          flex-direction: row;
          align-items: center;

          input {
            min-width: 100px;
          }

          & > span {
            -webkit-line-clamp:2;
          }
        }
      }

      .form-head{
        display: grid;
        grid-template-columns: calc(100% - 120px) 100px;
        row-gap: 14px;
        column-gap: 20px;
        padding-bottom: 24px;
        position: relative;

        .form-head-row {
          grid-column-start: 1;
          grid-column-end: 2;
          display: flex; 
          gap: 18px;
          
          &:nth-of-type(1) {
            .form-plan_no{
              width: 110px;

              span {
                text-transform: capitalize;
              }

              input{
                font-weight: 700;
              }
            }
            .form-plan_name{
              flex: 1;

              span {
                text-transform: capitalize;
              }
            }
            .form-fTemp{
              width: 156px;
            }
            .form-emergency_marker{
              width: 110px;
            }
            .form-date{
              width: 158px;
              input {
                padding-right: 0;
              }
            }
          }

          &:nth-of-type(2) {
            .form-drone_type{
              width: 150px;
            }
            .form-place{
              flex: 1;
            }
            .form-division{
              flex: 1;
            }
          }
        }
        
        .btn-save-plan {
          grid-column-start: 2;
          grid-column-end: 3;
          grid-row-start: 1;
          grid-row-end: 2;
          width: 100px;
          height: 34px;
          display: flex;
          justify-content: center;
          align-items: center;
          background: #5F55C4;
          border-radius: 3px;
          color: #FFF;
          font: 600 12px Inter;
          border: 0;
          margin-top: auto;

          &:disabled {
            opacity: 0.4;
            cursor: unset;
          }
        }

        .btn-duplicate{
          padding: 0;
          border: 0;
          background: transparent;
          width: 24px;
          height: 34px;
          display: flex;
          justify-content: center;
          align-items: center;
          align-self: flex-end;
          justify-self: center;

          &:disabled{
            opacity: 0.4;
            cursor: default;
          }

          img {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        }

        .btn-goback{
          width: max-content;
          height: 34px;
          padding-inline: 12px;
          background: #5F55C4;
          border: 0;
          border-radius: 3px;
          color: #fff;
          font: 600 12px Inter;
          cursor: pointer;
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 4px;

          position: absolute;
          top: calc(100% - 12px);
          right: 0;
          z-index: 10;

          svg {
            transform: rotate(90deg);
          }
        }
      }

      .tab-content{
        flex: 1 1;
        flex-basis: 0;
        overflow-y: auto;  

        .main_form{
          width: 100%;
          margin-bottom: 20px;
          border-bottom: 1px solid $in_border;

          tr{
            --col: 4;
            display: flex;
            margin-bottom: 25px;
            gap: 15px;

            td{
              width: calc((100% - (var(--col) - 1) * 15px) / var(--col));
              display: block;
            }
          }
        }

        .form-csv{
          width: 100%;
          display: flex;
          align-items: center;
          gap: 28px;
          margin-bottom: 16px;
          padding-bottom: 16px;
          border-bottom: 1px solid $in_border;

          .form-kiriha_mode{
            gap: 8px;
            .switch{
              width: 38px;
              height: 21px;
              border: 0;
              input:checked + .slider {
                background-color: #5F55C4;
              }
            }
          }

          .form-capture_distance{
            display: flex;
            align-items: center;
            gap: 4px;
            label {
              height: 30px;

              input {
                width: 64px;
                min-width: unset;
              }
            }
          }

          .form-face_csv, .form-marker_csv{
            gap: 6px;

            span {
              color: $dark_neutral;
              font: 400 10px Inter;
              line-height: 15px; /* 150% */
            }
          }

          .form-item {
            button {
              width: 105px;
              height: 30px;
              color: #5F55C4;
              font: 400 12px Inter;
              border: 1px solid #5F55C4;
              border-radius: 3px;
              display: flex;
              align-items: center;
              gap: 10px;
              background: $white;
            }
          }
        }

        .form-marker{
          display: flex;
          flex-direction: column;

          .marker_total{
            width: 100%;
            display: flex;
            align-items: center;
            font-size: 14px;
            margin-bottom: 10px;
            gap: 40px;

            span {
              color: $dark_neutral;
              font: 500 12px Inter;
              line-height: 15px; /* 125% */
            }
          }

          .marker-more{
            width: 100%;
            display: flex;
            flex-direction: column;
            gap: 12px;
            margin: 0;
            padding: 0;
            
            .marker_item {
              overflow: hidden;
              border: 1px solid #E8E4E3;
              border-radius: 4px;
              width: 100%;
              display: flex;
              justify-content: space-between;

              &.kiriha_marker .marker_item-content > *{
                display: none;
              }

              .marker_item-number {
                width: 133px;
                padding-block: 14px;
                padding-inline: 24px 10px;
                position: relative;
                background: #F6F5F4;
                border-right: 1px solid #E8E4E3;

                .icon-drag{
                  position: absolute;
                  top: 6px;
                  left: 3px;
                  cursor: move;
                }

                .form-marker{
                  display: flex;
                  flex-direction: column;
                  position: relative;

                  & > label {
                    color: #3E3A39;
                    font: 500 12px Inter;
                    line-height: 15px; /* 125% */
                    margin-bottom: 4px;
                    margin-left: 30px;
                  }

                  .form-marker-input{
                    display: flex;
                    align-items: center;
                    gap: 6px;
                    margin-bottom: 8px;

                    svg{
                      width: 18px;
                      height: 18px;
                      color: $dark_neutral;
                    }

                    input {
                      height: 30px;
                      padding: 6px 10px;
                      background: $white;
                      border: 1px solid #7168CA;
                      border-radius: 3px;
                      color: #000;
                      font: 400 14px Inter;
                    }

                    .marker-ids{
                      flex: 1;

                      & > .dps-select {
                        width: 100%;
                        
                        & > svg {
                          width: unset;
                          height: unset;
                        }
                      }
                    }
                  }

                  .error-message{
                    height: unset;
                    white-space: unset;
                    font-size: 11px;
                  }
                }
              }

              .marker_item-content{
                flex: 1;
                padding: 0 20px;
                display: flex;
                align-items: center;
                gap: 18px;

                .form-item {
                  label {
                    height: 30px;
                  }

                  &.form-marker-drone-angle {
                    max-width: 125px;
                  }
                  &.form-marker-drone-height {
                    width: 70px;
                    max-width: 70px;
                  }
                  &.form-marker-drone-wall_distance {
                    max-width: 110px;
                  }
                  &.form-marker-drone-landing_port {
                    max-width: 135px;
                  }
                  &.form-marker-drone-direction {
                    max-width: 120px;
                  }
                  &.form-marker-drone-marker_distance {
                    max-width: 162px;
                  }
                  &.form-marker-drone-mTemp {
                    max-width: 110px;
                  }
                  &.form-marker-drone-speed {
                    max-width: 100px;
                  }
                }
              }

              .marker_item-action{
                padding: 10px 11px;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                background: #F6F5F4;
                border-left: 1px solid #E8E4E3;

                button {
                  width: 18px;
                  height: 18px;
                  padding: 0;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  border-radius: 100rem;
                  border: 1px solid #5F55C4;
                  background: #FFF;
                  color: #5F55C4;
                  flex-shrink: 0;

                  &:disabled{
                    opacity: 0.4;
                    cursor: default;
                  }
                }
              }
            }
          }
        }
      }
    }

    .form-select{
      background: transparent;
      padding: 0;
      border: 0;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      span{
        display: block;
        margin-bottom: 4px;
        display:-webkit-box;
        -webkit-line-clamp:1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        word-break: break-word;
        font: 400 12px 'Inter', sans-serif;
      }

      label{
        width: 100%;
        border: 1px solid $in_border;
        border-radius: inherit;
        position: relative;
        font-size: 14px;

        span{
          position: absolute;
          top: calc(100% + 5px);
          left: 16px;
          margin-left: 0;
        }

        select, input{
          width: 100%;
          appearance: none;
          border: 0;
          background: transparent;
          padding: 16px 12px;
          height: auto;
          border-radius: inherit;
          font: 400 16px 'Inter', sans-serif;
        }

        select{
          padding-right: 26px;
        }

        select option{
          font: 400 16px 'Inter', sans-serif;
        }

        svg{
          color: $secondary;
          position: absolute;
          top: 50%;
          right: 15px;
          transform: translateY(-50%);
          user-select: none;
          pointer-events: none;
        }
      }

      .switch{
        width: 38px;
        border: 0;
        margin-bottom: auto;

        input {
          display: none;
        }

        .slider{
          top: 0;
          left: 0;
          margin: 0;
        }
      }
    }

    .scroll-bar {
      height: 100%;
    }

    .notice {
      font-weight: bold;
    }

    .nav.nav-pills {
      min-width: fit-content;
      margin-bottom: 10px;
      border-bottom: 3px solid $in_border;

      .nav-link{
        background: transparent;
        color: $label;
        font: 700 14px 'Inter', sans-serif;

        &.disabled {
          opacity: 0.5;
          pointer-events: all !important;
          cursor: no-drop;
          
          &:hover {
          }
        }
      }
      .active{
        position: relative;

        &::before{
          content: '';
          position: absolute;
          top: 100%;
          left: 0;
          width: 100%;
          height: 3px;
          background: #473DAF;
        }
      }
    }

    .tab-content {
      width: 100%;
      background-color: $white;
      border: 1px solid $in_border;
      border-radius: 10px;
      overflow: hidden;

      .active {
        background-color: white;
      }

      .tab-pane {
        width: 100%;
      }
    }

    td.marker-item-content {
      //min-width: 440px;
      p.marker-item-fieldname {
        display: grid;
        grid-template-columns: 110px 1fr;
        align-items: center;

        .with-50percent {
          width: 50%;
        }
      }
    }
  }

  .btn-upload {
    width: 30px;
    max-width: 30px;
    margin-right: 10px;
  }

  .btn-add {
    width: 30px;
    max-width: 30px;
    background-color: $c-white;
    border: 2px solid $c_black;
    margin-right: 10px;
  }

  .form-map {
    margin: 8px auto;

    .dps-button {
      width: 160px;
      background-color: $c-green;
      padding: 0 40px;
    }

    .dps-button[disabled] {
      background-color: $bg-gray;
    }
  }

  .flight-btn-group {
    margin-right: 40px;
    float: right;
  }

  .mg-l-100 {
    margin-left: 100px;
  }

  .error-message {
    color: $c-red;
    height: 20px;
    font-size: 0.8rem;
    min-width: 100px;
    white-space: nowrap;
  }

  .menu-zone {
    .dps-button {
      color: $c_white;
      background-color: $c_green;
      font-size: 1rem;
      border-radius: 0.25rem;
      text-align: center;
      padding: 0 10px;
      min-width: fit-content;
    }

    // min-width: 600px;
  }
}

.dps-content-tablet {
  .flight-plan .flight-plan-detail .form-plan .tab-content {
    flex-basis: unset; 
    height: 100vh;
  }
  .flight-plan .flight-plan-detail .form-plan .tab-content .form-marker {
    display: flex;
    padding-bottom: 300px;
    flex-direction: column;
  }

  .marker_item-number input{
    width: 50px;
  }
}

.noty-plan {
  .notification-success{
    &::before{
      content: 'Saved' !important;
      position: static !important;
    }
  }
}

// Mobile
.dps-mobile-view .flight-plan-mb {
  padding: 0 !important;

  .flight-plan-detail{
    min-width: unset;

    .plan-content-mb{
      height: 100%;
      padding: 12px 8px;
      background: #fff;
      display: flex;
      flex-direction: column;
      gap: 12px;
      overflow: auto;
  
      .plan-info{
        display: flex;
        flex-direction: column;
        gap: 6px;
  
        p {
          margin: 0;
          font: 600 12px Inter;

          label {
            min-width: 130px;
            font-weight: normal;
          }
        }
        
        .btn-goback{
          background: #5F55C4;
          border: 1px solid #5F55C4;
          border-radius: 3px;
          display: flex;
          align-items: center;
          padding: 4px 8px;
          gap: 4px;
          width: max-content;
          font: 500 12px Inter;
          color: #fff;
          margin-bottom: 8px;

          svg {
            transform: rotate(90deg);
          }
        }
      }

      .action-data{
        width: 100%;
        display: flex;
        align-items: center;
        gap: 12px;

        button {
          max-width: 50%;
          padding: 6px 12px;
          background: transparent;
          border: 1px solid #5f55c4;
          border-radius: 3px;
          color: #5f55c4;
          font: 400 14px Inter;
        }
      }

      table.flight-marker{
        thead {
          tr{
            border-bottom: 1px solid #000;

            th {
              &:first-child{
                min-width: 50px;
              }

              span{
                font: 500 12px Inter;
                padding-block: 3px;
                display:-webkit-box;
                -webkit-line-clamp:2;
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;
                word-break: break-word;
                text-align: center;
              }
            }
          }
        }

        tbody {
          tr{
            border-bottom: 1px solid $c-gray;
            td {
              padding-block: 4px;
              text-align: center;
            }
          }
        }
      }

      // Modal FaceData or MarkerData
      .data-modal{
        width: 100%;
        height: 100%;
        overflow: auto;

        .header{
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 12px;

          h4{
            margin: 0;
            font: 600 14px Inter;
          }

          button {
            border: 0;
            padding: 0;
            background: transparent;
            width: 16px;
            margin-right: 12px;

            svg {
              width: 100%;
            }
          }
        }

        .table-data{
          width: 100%;
          border: 1px solid #000;

          th,td {
            border: 1px solid #000;
            padding: 4px 8px;
            font: 400 12px Inter;

            &:first-child{
              width: 90px;
            }
          }

          th{
            font-weight: 700;
          }
        }
      }
    }
  }
}