.kiriha-modal-wrapper{
  width: calc(100vw - 190px) !important;
  height: 100vh;
  max-height: 100vh;
  margin-left: auto !important;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  
  .modal-content{
    height: 100%;
    flex: 1;
    overflow: hidden;
    padding: 16px 20px;

    .modal-header{
      display: flex;
      align-items: center;
      gap: 16px;
      padding: 0;
      margin-bottom: 17px;
      border: 0;
      position: relative;

      h3 {
        margin: 0;
        color: $dark_neutral;
        font: 700 15px Inter;
        line-height: 15px; /* 100% */
      }

      button {
        height: 34px;
        background: transparent;
        border: 1px solid #473DAF;
        border-radius: 3px;
        color: #473DAF;
        display: flex;
        justify-content: center;
        align-items: center;

        &:disabled{
          opacity: 0.4;
          pointer-events: none;
        }
        
        &.btn-clear-data {
          font: 600 12px Inter;
          padding-inline: 12px;
        }

        &.btn-save-csv{
          width: 100px;
          color: #FFF;
          background: #5F55C4;
          font: 600 12px Inter;
          line-height: 15px; /* 125% */
        }

        &.btn-close-edit{
          padding: 7px;
          width: 34px;
          height: 34px;
          border: 0;
        }

        &.btn_xyz_explanation {
          width: 24px;
          height: 24px;
          padding: 0;
          border: 0;
          margin-right: auto;

          img {
            width: 100%;
            object-fit: contain;
          }
        }
      }

      .btn-download-csv {
        height: 34px;
        background: transparent;
        border: 1px solid #473DAF;
        border-radius: 3px;
        color: #473DAF;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 9px;
        font: 500 12px Inter;
        text-decoration: none;
        padding-inline: 6px;
      }

      .mess-invalid{
        position: absolute;
        top: 100%;
        left: 0;
        color: #E64D5B;
        font: 400 12px Inter;
        line-height: 12px; /* 100% */
      }
    }
    
    .kiriha-modal-body{
      padding: 0;
      display: flex;
      flex-direction: column;
      overflow: hidden;

      button {
        padding: 0;
        margin: 0;
        background: transparent;
        border: 1px solid #000;
        width: 20px;
        height: 20px;
        border-radius: 100rem;
        display: inline-flex;
        justify-content: center;
        align-items: center;
      }

      .kiriha-container {
        width: 100%;
        max-width: 100%;
        display: flex;
        overflow: hidden;
  
        .ag-theme-alpine{
          flex: 1;

          .kiriha-header-table{
            max-width: 100%;
            flex: 1;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;

            p {
              margin: 0;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              flex: 1;
              cursor: pointer;
            }

            button {
              flex-shrink: 0;
              margin-left: 5px;
            }

            .form-add-new{

              input {
                width: 100%;
              }
            }

            .form-update{
              width: 100%;

              input {
                width: 100%;
              }
            }
          }

          // Custom ag-grig
          .ag-header-container {
            width: 100% !important;
            .ag-header-row {
              width: 100% !important;
              display: flex;
              background: #F6F5F4;

              .ag-header-cell {
                position: static;
                flex-shrink: 0;
                text-align: center;

                &:nth-last-of-type(1),
                &:nth-last-of-type(2) {
                  flex: 1;
                  min-width: 120px;

                  .ag-header-cell-label{
                    justify-content: flex-start;
                  }
                }

                .ag-header-cell-label{
                  display: flex;
                  justify-content: center;
                  align-items: center;

                  .ag-header-cell-text{
                    color: #000;
                    font: 500 14px Inter;
                    line-height: 15px; /* 107.143% */
                  }
                }
              }
            }
          }

          .ag-center-cols-container{
            width: 100% !important;

            .ag-row {
              display: flex;
              background: #fff;

              &:last-child {
                border: 0 !important;
              }

              &.ag-row-odd{
                background: #FAFAF9;
              }

              .ag-cell {
                position: static;
                flex: unset;
                flex-shrink: 0;
                padding: 0 20px !important;
                text-align: center;
                color: #000;
                font-size: 14px;

                &.invalid_marker_size{
                  border: 1px solid red;
                  background: #ffcccc;
                }

                input[class^=ag-] {
                  &:invalid {
                    border: 1px solid #E64D5B;
                  }
                }

                &:nth-last-of-type(1),
                &:nth-last-of-type(2) {
                  flex: 1;
                  min-width: 120px;
                  text-align: left;
                }

                &.ag-cell-inline-editing {
                  box-shadow: none;
                  border: 0;
                  .ag-cell-edit-wrapper {
                    width: calc(100% + 40px);
                    margin-left: -20px;
                  }
                }

                &.ag-cell-focus:not(.ag-cell-ranger-selected):focus-within {
                  border-color: #5F55C4;
                }

                .custom-cell-input{
                  width: calc(100% + 40px);
                  height: 100%;
                  margin-left: -20px;
                  border: 1px solid rgba(33, 150, 243, 0.4);
                  border-radius: inherit;
                  overflow: hidden;
                  display: flex;

                  input {
                    width: 100%;
                    height: 100%;
                    border: 0;
                    outline: none;
                    padding-inline: 6px 2px;
                    appearance: none;

                    &::-webkit-outer-spin-button,
                    &::-webkit-inner-spin-button {
                      -webkit-appearance: none;
                    }
                  }

                  &.cell-invalid {
                    border: 1px solid #E64D5B;
                  }
                }
              }
            }
          }
        }

        .actions-row{
          width: 72px;
          display: flex;
          flex-direction: column;
          margin-top: 41px;
          overflow: hidden;
          
          &::-webkit-scrollbar{
            display: none;
          }

          .item{
            width: 100%;
            height: 40px;
            display: flex;
            align-items: center;
            gap: 12px;
            flex-shrink: 0;
            padding-inline: 12px;

            .btn-add-row, .btn-del-row {
              width: 18px;
              height: 18px;
              display: flex;
              justify-content: center;
              align-items: center;
              color: #5F55C4;
              font: 500 13px Inter;
              border: 1px solid #5F55C4 !important;
            }
          }
        }
      }

      .explanation_wrap{
        width: 100%;
        display: flex;
        justify-content: center;

        img {
          max-width: 100%;
        }
      }
    }
  }

  .ag-body-vertical-scroll {
    position: absolute;
    top: 0;
    right: 0;
  }

  .ag-body-horizontal-scroll {
    position: absolute;
    bottom: 0;
    left: 0;
  }

  .ag-overlay{
    padding-top: 40px;

    .ag-overlay-wrapper {
      padding-top: 0 !important;
    }
  }
}

// common map preview
.map-preview-kiriha-mode{
  width: 100%;
  height: 100%;
  position: relative;
  flex: 1;

  .img-actions{
    position: absolute;
    top: 9px;
    right: 13px;
    display: flex;
    gap: 6px;
    z-index: 3;

    button {
      border: 0;
      background: $white;
      overflow: hidden;
      display: flex;
      justify-content: center;
      align-items: center;

      &:hover {
        background: #E9E8F7;
      }

      &.active {
        background: #7168CA;
      }
    }

    .view-point-category{
      display: flex;
      align-items: center;
      border: 1px solid #7168CA;
      border-radius: 4px;
      background: #FFFFFF99;

      .check-point-view {
        margin: 0 10px;

        .icon-point{
          display: inline-block;
          width: 10px;
          height: 10px;
          border-radius: 100rem;
          background: var(--color);
        }

        span {
          font-size: 11px;
        }
      }
    }

    .action-img{
      display: flex;
      align-items: center;
      border: 1px solid #7168CA;
      border-radius: 4px;
      overflow: hidden;

      button {
        width: 30px;
        height: 30px;
      }
    }

    .btn-move, .btn-point-category{
      width: 32px;
      height: 32px;
      border: 1px solid #7168CA;
      border-radius: 4px;
    }

    .btn-point-category {
      padding: 0;
      position: relative;
      overflow: unset;

      &.active {
        background: #E9E8F7;

        .view-point-category {
          display: flex;
        }
      }

      svg {
        flex-shrink: 0;
      }

      .btn-close-select{
        width: 13px;
        height: 13px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0;
        position: absolute;
        top: 10px;
        right: 9px;
        background: transparent;

        svg {
          width: 100%;
          height: 100%;
          pointer-events: none;
        }
      }

      .view-point-category{
        width: 120px;
        display: none;
        position: absolute;
        top: calc(100% + 2px);
        right: 0;
        flex-direction: column;
        align-items: flex-start;
        gap: 20px;
        padding: 20px 12px 16px;
        background: #FFFFFF;

        .check-point-view{
          margin: 0;
        }
      }
    }
  }

  .onward {
    --color: #FF9E66;
  }
  .return {
    --color: #FF73AD;
  }
  .kiriha {
    --color: #66B2FF;
  }

  .marker-preview{
    position: absolute;
    top: 0;
    left: 0;

    .marker-icon{
      transform: translate(-50%, -50%);
    }
  }

  .station-preview{
    position: absolute;
    top: 0;
    left: 0;

    .station-icon{
      transform: translate(-50%, -50%);
      width: 24px;
      height: 24px;
      border-radius: 3px;
      overflow: hidden;
      background: #EBEBEB;
      box-shadow: 1px 2px 2px #000;
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }

  .point-flight{
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;

    .point-icon {
      display: block;
      width: 6px;
      height: 6px;
      border-radius: 100rem;
      background: var(--color);
    }

    &.on-marker .point-icon {
      width: 7px;
      height: 7px;
      border: 1px solid #fff;
      box-shadow: 0 0 0 1.5px var(--color);
    }
  }

  .drone-flight{
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    transition: transform 0.3s ease-in-out;

    .drone-icon{
      width: 30px;
      height: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: #fff;
      border-radius: 8px;
      position: relative;

      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: #fff;
        z-index: 1;
        border-radius: inherit;
      }

      svg {
        z-index: 1;
      }

      @-webkit-keyframes rippleKiriha {
        0% {
          opacity: 0.5;
          -webkit-transform: scale(1);
          transform: scale(1);
        }
        100% {
          opacity: 0;
          -webkit-transform: scale(1.2);
          transform: scale(1.2);
        }
      }
      @keyframes rippleKiriha {
        0% {
          opacity: 0.5;
          -webkit-transform: scale(1);
          transform: scale(1);
        }
        100% {
          opacity: 0;
          -webkit-transform: scale(1.2);
          transform: scale(1.2);
        }
      }

      .ripple {
        width: 115%;
        height: 115%;
        position: absolute;
        opacity: 0;
        border-radius: 8px;
        animation: rippleKiriha 1.8s infinite;
        z-index: -1;

        &:nth-last-of-type(2) {
          animation-delay: 0.3s;
        }
      }
    }
  }

  .status-line{
    position: absolute;
    top: 0;
    left: 0;

    line {
      stroke: var(--color) !important;
    }
  }

  @-webkit-keyframes viewLastPoint {
    to {
      opacity: 1;
    }
  }
  @keyframes viewLastPoint {
    to {
      opacity: 1;
    }
  }

  .delay-view{
    opacity: 0;
    animation: viewLastPoint forwards;
    animation-delay: 0.3s;
  }

  .kiriha-label{
    top: 20px;
    left: 20px;
    transform: unset;
    padding: 0;
  }
}

.card-content.main-full-screen {
  .map-preview-kiriha-mode{
    margin-left: 190px;
  }
}

// plan

.tabcontent-kiriha-mode{
  display: flex;

  .marker-more{
    .marker_item{
      min-height: 80px;

      .marker_item-number {
        width: 165px !important;

        .form-marker{
          flex: 1;
          margin-top: 19px;

          .form-marker-input {
            margin: 0 !important;
          }
        }
      }

      .marker_item-content {
        padding-block: 14px !important;
        align-items: flex-start !important;
      }
    }
  }

  .map-zone{
    gap: 16px;
    padding: 16px 15px !important;

    .content-map-zone {
      width: 205px;
      padding: 0;
      
      .form-control-kiriha {
        display: flex;
        flex-direction: column;
        margin-bottom: 20px;
        
        .tab-title{
          font: 700 13px Inter;
          color: #000;
          margin-bottom: 6px;
        }
  
        .tab-desc{
          font: 400 12px Inter;
          color: #000;
          padding-left: 13px;
          margin-bottom: 6px;
        }
  
        .tab-from{
          margin: 0;
  
          .mask-tab-form{
            width: 145px;
            height: 30px;
            margin: 3px auto;
            background: #5F55C4;
            border-radius: 3px;
            display: flex;
            justify-content: center;
            align-items: center;
  
            span {
              font: 600 12px Inter;
              color: #fff;
            }
          }
          
          .row-control{
            height: 28px;
            display: flex;
            align-items: center;
            justify-content: space-between;
  
            & > span {
              flex: 1;
              display: flex;
              align-items: center;
              gap: 5px;
  
              svg {
                width: 22px;
                height: 22px;
              }
  
              label {
                font: 400 12px Inter;
                color: #000;
                margin: 0;
              }
            }

            & > label {
              margin-bottom: 0;
              cursor: pointer;
            }
          }
  
          &.form-setting-point, &.form-second-actions {
            display: flex;
            flex-direction: column;
            padding-left: 13px;
          }
        }

        .view-route{
          width: max-content;
          height: 30px;
          margin: 3px auto;
          border-radius: 3px;
          border: 0;
          font: 600 12px Inter;
          color: #fff;
          background-color: #5F55C4;
          display: flex;
          align-items: center;
          gap: 6px;
    
          &:disabled{
            background-color: #AAA3A2;
          }
    
          &.active{
            background-color: #5F55C4 !important;
          }
        }
      }
    }
    
    .preview-map-zone{
      flex: 1;
      border: 1px solid #E8E4E3;
      border-radius: 6px;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      
      .img-actions{
        position: absolute;
        top: 9px;
        right: 13px;
        display: flex;
        gap: 6px;
        z-index: 3;

        button {
          border: 0;
          background: $white;
          overflow: hidden;
          display: flex;
          justify-content: center;
          align-items: center;

          &:hover {
            background: #E9E8F7;
          }

          &.active {
            background: #7168CA;
          }
        }

        .action-img{
          display: flex;
          align-items: center;
          border: 1px solid #7168CA;
          border-radius: 4px;
          overflow: hidden;

          button {
            width: 30px;
            height: 30px;

            svg {
              pointer-events: none;
            }
          }
        }

        .btn-move{
          width: 32px;
          height: 32px;
          border: 1px solid #7168CA;
          border-radius: 4px;
        }
      }
      
      .image-map-view-area{
        display: flex;
        overflow: auto;

        .image-map-view {
          flex-shrink: 0;

          .calibration-point, .origin-point {
            position: absolute;
            top: 0;
            left: 0;
            cursor: move;
            user-select: none;
            z-index: 10;
            
            & > div {
              display: flex;
              justify-content: center;
              align-items: center;
              position: absolute;
              top: 0;
              left: 0;
              transform: translate(-50%, -50%);
            }
          }

          .axis-system {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            pointer-events: none;
            
            .axis-origin-point {
              z-index: 1;
              position: absolute;
              top: 0;
              left: 0;
              width: 3px;
              height: 3px;
              border-radius: 100rem;
              background: #2ecc71;
            }

            .axis{
              svg {
                position: absolute;
                top: 0;
                left: 0;
              }
            }
          }
        
          .device-preview {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        
            .station-map-editer{
              position: absolute;
              top: 0;
              left: 0;
              z-index: 1;
              
              .station-icon{
                width: 24px;
                height: 24px;
                border-radius: 3px;
                overflow: hidden;
                background: $border;
                box-shadow: 1px 2px 2px #000;
        
                img {
                  width: 100%;
                  height: 100%;
                  object-fit: cover;
                }
              }
            }
        
            .marker-map-editer{
              position: absolute;
              top: 0;
              left: 0;
              z-index: 1;
              display: flex;
              justify-content: center;
              align-items: center;
            }
          }
      
          .flight-route-animate{
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;

            .drone-icon-in-map{
              z-index: 1000;
            }
          }
        }
      }

      .no-img {
        font: 700 14px Inter;
        color: #AAA3A2;
      }
    }
  }
}


.form-setting-calibration{
  position: fixed;
  z-index: 999999;
  display: flex;
  flex-direction: column;
  gap: 14px;
  width: 120px;
  padding: 10px 12px;
  border: 1px solid #A6A0DE;
  border-radius: 4px;
  background: #fff;

  label {
    font: 400 11px Inter;
    color: $dark_neutral;
  }

  .form-head{
    display: flex !important;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 0 !important;

    .btn-close {
      width: 13px;
      height: 13px;
      padding: 0;
    }
  }

  .row-control {
    display: flex;
    align-items: center;
    justify-content: space-between;

    input {
      width: 60px;
      border: 1px solid #7168CA;
      border-radius: 3px;
    }
  }

  .btn-sub{
    width: 60px;
    height: 24px;
    border: 0;
    background: #5F55C4;
    color: #fff;
    font: 400 10px Inter;
    border-radius: 3px;
    align-self: flex-end;
  }
}

.form-edit-marker-data{
  width: 120px;
  height: 180px;
  padding: 10px 12px;
  position: fixed;
  background: #fff;
  border: 1px solid #A6A0DE;
  border-radius: 4px;
  z-index: 9999;
  display: flex;
  flex-direction: column;
  gap: 10px;

  label {
    font: 400 11px Inter;
    color: $dark_neutral;
  }

  .form-head {
    display: flex !important;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 0 !important;
    margin-bottom: 4px;

    .btn-close {
      width: 13px;
      height: 13px;
      padding: 0;
    }
  }

  .row-control{
    display: flex;
    align-items: center;
    justify-content: space-between;

    input {
      width: 60px;
      height: 24px;
      border: 1px solid #7168CA;
      border-radius: 3px;
      text-align: right;
      font-size: 13px;

      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
    }
  }

  .btn-submit{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60px;
    height: 24px;
    border: 1px solid #5F55C4;
    border-radius: 3px;
    font: 400 10px Inter;
    color: #fff;
    background: #5F55C4;
    text-transform: uppercase;
    align-self: flex-end;
  }
}

// status
.status-map-view{
  width: max-content;
  height: max-content;
  position: relative;

  .marker-preview-ready{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    .marker-item, .drone-flight, .station-preview{
      position: absolute;
      top: 0;
      left: 0;
    }

    .station-preview {
      width: 24px;
      height: 24px;
      border-radius: 3px;
      overflow: hidden;
      background: #EBEBEB;
      box-shadow: 1px 2px 2px #000;
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
}


@media only screen and (max-width: 576px) {
  .map-preview-kiriha-mode{
    .kiriha-label {
      top: 10px;
      left: 10px;
      svg {
        width: 90px;
        height: 60px;
      }
    }
  }
}