// Style Select tag
.dps-select {
  width: max-content;
  background: transparent;
  border: 1px solid #7168CA;
  border-radius: 3px;
  position: relative;
  height: 30px;
  padding: 0;
  margin: 0;
  overflow: hidden;

  select {
    width: 100%;
    height: 100%;
    padding: 6.5px 26px 6.5px 10px;
    appearance: none;
    color: #3E3A39;
    font: 500 12px Inter;
    line-height: 15px; /* 125% */
    border-radius: inherit;
    border: 0;
    outline: none;
  }

  svg {
    pointer-events: none;
    position: absolute;
    width: 16px;
    height: 16px;
    color: #473DAF;
    right: 4px;
    top: 50%;
    transform: translateY(-50%);
  }
}


// Style Checkbox
.dps-checkbox-custom {
  width: max-content;
  display: flex;
  align-items: center;

  .form-checkbox-custom {
    display: flex;
    justify-content: center;
    align-items: center;

    input{
      display: none;
    }

    svg {

    }
  }

  span {
    color: $dark_neutral;
    font: 400 12px Inter;
    margin-left: 5px;
    display: flex;
    align-items: center;
    gap: 3px;
  }
}

// Style Select Custom
.dps-select-custom{
  width: 100%;
  border: 1px solid #7168CA;
  border-radius: 3px;
  position: relative;
  min-height: 28px;

  .dps-select-custom-content {
    width: 100%;
    padding: 6.5px 10px;
    padding-right: 26px;

    .item-selected{
      font: 400 12px Inter;
      line-height: 15px;
      height: 15px;
      margin: 0;
      pointer-events: none;
      display:-webkit-box;
      -webkit-line-clamp:1;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      word-break: break-word;
    }

    .select-list{
      background: #fff;
      position: absolute;
      top: 100%;
      left: 0;
      z-index: 5;
      width: calc(100% + 2px);
      height: max-content;
      margin: 0;
      padding: 0;
      margin-top: 1px;
      margin-left: -1px;
      border-radius: 3px;
      list-style: none;
      border: 1px solid $label;
      box-shadow: 0 1px 3px $c-gray;
      max-height: 400px;
      overflow: auto;

      .item {
        padding: 3px 10px;
        font: 400 12px Inter;
        line-height: 15px;
        display:-webkit-box;
        -webkit-line-clamp:1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        word-break: break-word;

        &:hover, &.selected{
          color: $white;
          background: #4e89ec;
        }

        &.title {
          width: auto;
          font-size: 13px;
          border-bottom: 1px solid $dark_neutral;
          user-select: none;
          max-width: calc(100% - 6px);
          margin: auto;
          padding-left: 7px;

          &:hover {
            background: unset;
            color: unset;
          }
        }
      }

      &.drop-up {
        top: unset;
        bottom: 100%;
        box-shadow: 0 -1px 3px $c-gray;
        margin-top: 0;
        margin-bottom: 1px;
      }
    }
  }

  & > svg {
    width: 16px;
    height: 16px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 4px;
    pointer-events: none;
  }
}

// Style Radio Custom
.dps-radio-custom{
  display: flex;
  align-items: center;
  gap: 4px;

  & > span {
    color: $dark_neutral;
    font: 400 11px Inter;
  }

  .form-radio-custom{
    margin: 0;
    padding: 0;
    width: 16px !important;
    height: 16px !important;
    border: 1px solid #5F55C4;
    border-radius: 100rem;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    .checkmark{
      display: none;
      width: 8.5px;
      height: 8.5px;
      background: #5F55C4;
      border-radius: 100rem;
    }

    input {
      display: none;
    }

    input:checked + .checkmark {
      display: block;
    }
  }
}