.admin-page{
  .detail_setting_mode{
    margin-right: 0 !important;
    padding-right: 0 !important;
    border: 0 !important;
  }

  .dps-main-content{
    display: flex;
    flex-direction: column;
    padding: 0;
    flex: 1;

    .wrap_table{
      display: flex;
      flex-direction: column;
      
      .txt-empty{
        color: $c-red;
        font-size: 15px;
        font-weight: 700;
        margin-bottom: auto;
      }
    } 
    
    .dps-main-content-area{
      border-radius: 15px;
  
      .table-user{
        flex: 1 !important;
        border-bottom-right-radius: unset !important;
        border-bottom-left-radius: unset !important;
        overflow-y: auto;

        &::-webkit-scrollbar {
          width: 13px;
        }

        &::-webkit-scrollbar-thumb {
          background: #80808080;
          border-radius: 6px;
          border: 3px solid transparent;
          background-clip: padding-box;
        }
  
        .clmn {
          &:nth-child(1) {
            flex: 0 1 60px;
          }
          &:nth-child(2), &:nth-child(3), &:nth-child(4) {
            flex: 1 1 200px;
          }
          &:nth-child(5) {
            flex: 0 1 60px;
          }
          &:nth-child(6) {
            flex: 0 1 150px;
            text-align: center;
          }
        }

        .header{
          position: sticky;
          top: 0;
          background: #fff;
          z-index: 1;
        }

        .body {
          overflow: unset;
        }

        .actions {
          .btn-small{
            align-items: center;
            border: none;
            border-radius: 20px;
            display: inline-flex;
            flex-direction: row;
            flex-shrink: 0;
            height: 30px;
            margin: 5px;
            width: 30px;
  
            svg{
              width: 14px;
            }
          }

          .delete {
            background: transparent;
          }
        }
      }
  
      .add-item{
        width: 100%;
        padding: 20px;
        margin-bottom: 0;
        justify-content: space-between;
        gap: 20px;

        & > div{
          height: 100%;
          position: relative;

          input{
            font-size: 11px;
            border-right: 1px solid #EBEBEB;
            padding: 10px 20px 10px 12px;
            height: 100%;
          }

          button {
            height: 100%;
          }
        }
        
        .btn-save {
          border-radius: inherit;
          width: 40px;
          height: 40px;
        }

        .mash_drone_type{

          .btn_choose_drone_type{
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            border: 0;
            background: transparent;
            font: 400 11px 'Inter', sans-serif;
            padding: 6px 20px 6px 12px;
            border: $card-border;

            img{
              height: 20px;
            }
          }
        }
      }
    }
  }

  // setting profile
  .form-profile{
    .dps-input{
      border: 1px solid $in_border;
      border-radius: 5px;
      background: transparent !important;
    }

    .dps-button{
      background: $dark_neutral !important;
      border-radius: 5px !important;
      padding: 6px 12px !important;
      width: max-content !important;
      height: auto;
      min-width: 100px;
    }
  }

  // setting icon
  .form-icon .dps-button {
    width: max-content;
    background: $dark_neutral !important;
  }

  // setting parameter
  .params-table{
    border: 1px solid $border;

    thead{
      border-top-right-radius: inherit;
      border-top-left-radius: inherit;
      td:first-child{
        width: auto;
        text-align: left;
      }
    }
    tbody {
      td {
        padding: 0;
  
        &:first-child{
          width: auto;
          text-align: left;
        }
      }

      .inline-edit .edit-user{
        width: 100%;
        justify-content: flex-end;
        .item {
          margin: 0;

          & > div {

            input {
              width: 100%;
              border: 1px solid $in_border;
              border-radius: 5px;
              color: $c-black;
              font-size: 11px;
            }
  
            .input-error{
              transform: translateY(-50%);
              top: 50%;
              right: 100%;
              left: unset;
              bottom: unset;
              margin-right: 10px;
            }
          }
        }
      }
    }
  }

  // setting privacy-supports
  .admin-privacy-supports{
    display: flex;
    flex-direction: column;

    .header_edit_privacy-supports{
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 6px;

      .swicth_actions{
        display: flex;
        align-items: center;

        & > .input-radio-container {
          width: auto;
          height: auto;
          padding-left: 20px;
          white-space: nowrap;
          font-weight: bold;

          .checkmark{
            width: 18px;
            height: 18px;
          }
        }
      }

      button{
        border: $card-border;
        border-radius: 5px;
        margin-bottom: 10px;
        margin-right: 5px;
        font-size: 14px;
        padding: 12px 20px;
        background: $dark_neutral;
        color: $white;
        text-transform: capitalize;

        &[disabled]{
          opacity: 0.5;
          cursor: auto;
        }
      }
    }
    & > p{
      margin-bottom: 10px;
      text-align: right;
    }
    .quill .ql-container{
      overflow-y: auto;
    }
  }

  //
  .admin-setting {
    .add-new {
      width: max-content;
      padding: 8px 10px;
      margin-right: auto;
      margin-bottom: 20px;
      border-radius: 3px;
      border: 0;
      background: #5F55C4;
      color: $white;
      font: 600 12px Inter;
      line-height: 15px
    }

    .table-setting{
      margin-bottom: auto;

      .header {
        background: #FAFAF9;
      }
  
      .clmn{
        &:nth-child(1) {
          flex: 0 1 60px;
        }
        &:nth-child(2), &:nth-child(3), &:nth-child(4), &:nth-child(5), &:nth-child(6), &:nth-child(7) {
          flex: 1 1 100px;
          text-align: left;
        }
        &:nth-child(7){
          text-align: center;
          justify-content: center;
        }
      }
      .drone_img{
        height: 100%;
        padding: 10px 0;
        img{
          height: 100%;
          aspect-ratio: 1;
          object-fit: cover;
        }
      }
    }
  }
  
  .actions .btn-small.edit {
    background: $dark_neutral;
  }
}


.modal-content{
  .edit-user{
    .item > div {
      gap: 6px;
      .item-row{
        .from-st-wall_distance{
          display: flex;
          align-items: flex-end;
          gap: 6px;
          font-size: 13px;
          position: absolute;
          right: 0;
          top: 50%;
          transform: translateY(-50%);

          input {
            width: 60px;
            height: 26px;
            background: #fff;
            border: 1px solid #DBD7D6;
            border-radius: 5px;
            padding-left: 12px;
            background: #f8f8f8;
          }

          .err_mes {
            position: absolute;
            top: 100%;
            right: 0;
            font: 400 10px Inter; 
            color: $danger;
            white-space: nowrap;
          }
        }

        span {
          font-size: 13px;
        }
      }
    }
  }
}

@media only screen and (max-width: 992px) {
  .swicth_actions {
    flex-direction: column;
    align-items: flex-start !important;

    label{
      margin-bottom: 6px;
    }
  }
}