.inspection-result {
  background-color: transparent !important;

  .dps-list-f {
    .clmn {
      &:nth-child(1) {
        flex: 1 1 0;
        min-width: 100px;
      }
      &:nth-child(2) {
        flex: 2 1 0;
        min-width: 120px;
      }
      &:nth-child(3) {
        flex: 0 1 60px;
        text-align: center;
      }
      &:nth-child(4) {
        flex: 0 1 100px;
        text-align: center;
      }
      &:nth-child(5) {
        flex: 0 1 150px;
      }
    }
    .body {
      .clmn {
        &:nth-child(1) {
          font-weight: 700;
        }
        &:nth-child(2) {
          font-weight: 700;
        }
        svg {
          margin-right: 12px;
        }
      }
    }
  }

  .inspection-status-area {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;

    .drone-detail-status{
      .drone-detail-status-info {
        .device-item{
          .device-info {
            align-self: flex-start;
          }
        }

        .main-action-result{
          width: 100%;
          padding: 4px 8px 6px 12px;
          display: flex;
          align-items: center;
          gap: 6px;

          & > .dps-select{
            flex: 1;
          }

          .btn-delete-download {
            border: 0;
            background: transparent;
            width: 26px;
            height: 26px;
            padding: 0;
            border-radius: 2px;
            display: flex;
            justify-content: center;
            align-items: center;
            transition: .25s linear;

            &.active {
              background: #E9E8F7;
            }
            
            &:hover {
              background: #E9E8F7;
            }
          }
        }
      }
    }
  }
}

// common 
.streaming {
  position: relative;

  .video-gallery{
    width: 100%;
    height: 100%;

    .video-area{
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;

      video {
        width: 100%;
        height: 100%;
        background-color: #000;
        object-fit: contain;
      }

      .zoom-button{
        border: 0;
        border-radius: 12px;
        font-size: 10px;
        height: 24px;
        line-height: 24px;
        position: absolute;
        bottom: 38px;
        text-align: center;
        visibility: hidden;
        width: 60px;
      }

      &:hover .zoom-button {
        visibility: visible;
      }

      .loading{
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 1;
      }
    }
  }

  .action-change-video{
    position: absolute;
    bottom: 0;
    display: flex;
    align-items: center;
    gap: 10px;
    bottom: 7px;
  }
}

.map-image-preview{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  position: relative;

  .image-map-view{
    width: max-content;
    height: max-content;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

// render result map
.drone-selector{
  width: 100%;
  height: auto;
  max-height: 100%;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;

  .images-wrapper .image-header .actions {
    .btn-img-kiriha{
      svg {
        width: 24px;
        height: 24px;
      }
    }
  }
}


// Modal image zoom
.image-zom-preview {
  .modal-content {
    width: 98%;
    height: 96vh;
    position: relative;

    .modal-header {
      position: absolute;
      top: 0;
      right: 0;
      border: 0;
    }

    .modal-body {
      padding: 4px;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        max-width: 100%;
        max-height: 100%;
        border-radius: 10px;
        margin: 0 auto;
      }

      h1 {
        width: 100%;
        text-align: center;
        height: 200px;
        line-height: 200px;
      }
    }
  }
}

// Mobile
.dps-mobile-view .inspection-result {
  padding: 0 !important;

  .drone-selector{
    .drone-detail-status{
      .drone-detail-status-info {
        .drone{
          position: relative;

          .form-option{
            position: absolute;
            top: 32px;
            right: 8px;
          }
        }

        .main-action-result{
          position: relative;

          .form-option{
            position: absolute;
            top: 34px;
            right: 8px;
          }
        }
      }

      .drone-detail-status-content {
        .card-content {
          .no-video{
            margin: 0;
            padding: 10px;
            color: var(--gray-06-disabled, #AAA3A2);
            font: 700 14px Inter;
            line-height: 21px;
            flex: 1 1;
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
      }
    }
  }
}